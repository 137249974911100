import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";
import StripeBuyButton from "../components/StripeBuyButton";
import logo from "../logo-white.jpg";

import useVerifyAccess from "../hooks/useVerifyAccess";
import { useAuth } from "../hooks/useAuth";
import { useCompanyContext } from "../contexts/CompanyContext";

const License = () => {
  const navigate = useNavigate();
  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const { hasAccess, hasAccessIsLoading, refetch } = useVerifyAccess();
  const { companyData } = useCompanyContext();

  useEffect(() => {
    if (authenticationIsLoading) {
      return;
    }

    if (hasAccessIsLoading) return;

    if (!isAuthenticated) {
      navigate("/");
    }

    if (isAuthenticated && hasAccess) {
      navigate("/dashboard");
    }

    if (isAuthenticated) {
      if (companyData?.is_demo) {
        navigate("/dashboard");
      }
    }
  }, [
    navigate,
    isAuthenticated,
    authenticationIsLoading,
    hasAccess,
    hasAccessIsLoading,
    companyData?.is_demo,
  ]);

  useEffect(() => {
    const intervalId = setInterval(refetch, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [refetch]);

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography component="h1" variant="h5" mb={3} mt={2}>
              DitESG starter her 🌍
            </Typography>
            <Paper
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                borderRadius: 2,
                padding: 3,
                mb: 3,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.contrastText,
                }}
              >
                Tak for at registrere selskab hos DitESG! Køb og download din
                ESG-rapport. <br />
                Rapporten kan ændres og downloades ubegrænset.
                <br />
                Abonnementet træder i kraft på købsdatoen og fornyes årligt. Det
                kan opsiges når som helst.
              </Typography>
            </Paper>
            {/* Stripe payment section */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 6,
              }}
            >
              <StripeBuyButton />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default License;
